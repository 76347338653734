'use client'

import { useState, useEffect } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { Dialog, DialogPanel, DialogTitle } from '@headlessui/react'
import { Anchor, Ship, Compass, Check, Square, Twitter, Instagram, Facebook, X } from 'lucide-react'
import { db } from './firebase-config'
import { doc, setDoc, updateDoc } from 'firebase/firestore/lite';

import Logo from './logo-horizontal.png'
import SailBoatIcon from './sailboat.png'
import Person1 from './people/person-1.jpg'
import Person2 from './people/person-2.jpg'
import Person3 from './people/person-3.jpg'
import Person4 from './people/person-4.jpg'
import Person5 from './people/person-5.jpg'

const phrases = [
  "meet new friends",
  "find cabin mates",
  "share experiences",
  "connect before they sail",
  "discover new sailings",
  "explore 500+ ships",
  "plan their journeys",
  "ask questions",
]

const excitementOptions = [
  "To connect with cruisers",
  "To find cabin mates",
  "To learn about your sailing",
  "To explore ships and sailings",
  "To get cruising questions answered",
  "All of the above"
]

const people = [Person1, Person2, Person3, Person4, Person5]

// SUBMIT JOIN WAITLIST
async function joinWaitList(email: string) {
  const signupsRef = doc(db, 'signups', email);
  await setDoc(signupsRef, {
    email,
    timestamp: new Date(),
  });
}

// SUBIMT EXCITEMENT OPTIONS
async function updateMostExcitedAbout(email: string, mostExcitedAbout: string[]) {
  const signupsRef = doc(db, 'signups', email);
  await updateDoc(signupsRef, {
    mostExcitedAbout,
  });
}

// SUBMIT CONTACT FORM
async function submitContactForm(name: string, email: string, message: string) {
  const contactRef = doc(db, 'contacts', email);
  await setDoc(contactRef, {
    name,
    email,
    message,
    timestamp: new Date(),
  });
}

export default function WaitlistPage() {
  const [showBanner, setShowBanner] = useState(true)
  const [currentPhrase, setCurrentPhrase] = useState(0)
  const [isOpen, setIsOpen] = useState(false)
  const [isContactOpen, setIsContactOpen] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [selectedOptions, setSelectedOptions] = useState<string[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [contactName, setContactName] = useState('')
  const [contactEmail, setContactEmail] = useState('')
  const [contactMessage, setContactMessage] = useState('')
  const [joinedWaitlist, setJoinedWaitlist] = useState(false)

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentPhrase((prev) => (prev + 1) % phrases.length)
    }, 5000)
    return () => clearInterval(interval)
  }, [])

  const handleSubmit = async (e: React.FormEvent) => {
    setIsLoading(true)
    e.preventDefault()

    try {
      await joinWaitList(email)
      setIsSubmitted(true)
      setJoinedWaitlist(true)
    } catch (error) {
      alert('An error occurred. Please try again!')
    } finally {
      setIsLoading(false)
    }
  }

  const handleOptionToggle = (option: string) => {
    setSelectedOptions(prev =>
      prev.includes(option)
        ? prev.filter(item => item !== option)
        : [...prev, option]
    )
  }

  const handleFinalSubmit = async () => {
    setIsLoading(true)

    try {
      await updateMostExcitedAbout(email, selectedOptions)
    } catch (error) {
      alert('An error occurred. Please try again!')
    } finally {
      setIsLoading(false)
      setSelectedOptions([])
      setIsOpen(false)
      setIsSubmitted(false)
      setEmail('')
    }
  }

  const handleContactSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    setIsLoading(true)

    try {
      await submitContactForm(contactName, contactEmail, contactMessage)
    } catch (error) {
      alert('An error occurred. Please try again!')
    } finally {
      setIsLoading(false)
      setIsContactOpen(false)
      setContactName('')
      setContactEmail('')
      setContactMessage('')
    }
  }

  const Spinner = (
    <div role="status">
      <svg aria-hidden="true" className="mx-auto w-6 h-6 text-white animate-spin fill-primary-light" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
          <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
      </svg>
      <span className="sr-only">Loading...</span>
    </div>
  )

  return (
    <>
      {/* BANNER */}
      {showBanner && (
        <div className="flex justify-between items-center bg-yellow-300 text-dark py-2 px-6 text-center font-bold relative z-20">
          <div className="inline-block flex-1 self-center">
            <span className="animate-pulse mx-1">🛳️</span> Launching This Fall - Get early  access by joining the waitlist!  <span className="animate-pulse mx-1">🛳️</span>
          </div>
          <button
            onClick={() => setShowBanner(false)}
            className="text-dark hover:text-dark/80 transition-colors"
            aria-label="Close Banner"
          >
            <X className="w-5 h-5 inline-block" />
          </button>
        </div>
      )}
      {/* MAIN */}
      <div className="min-h-screen flex flex-col bg-gradient-to-br from-[#164f97] to-primary text-white px-6 py-4 relative">
        {/* Animated Waves */}
        <div className="absolute inset-0 pointer-events-none overflow-hidden">
          <svg className="absolute bottom-0 left-0 w-full" viewBox="0 0 1440 320" preserveAspectRatio="none">
            <path fill="rgba(255, 255, 255, 0.1)" fillOpacity="0.5" d="M0,192L48,197.3C96,203,192,213,288,229.3C384,245,480,267,576,250.7C672,235,768,181,864,181.3C960,181,1056,235,1152,234.7C1248,235,1344,181,1392,154.7L1440,128L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z">
              <animateTransform
                attributeName="transform"
                type="translate"
                dur="20s"
                repeatCount="indefinite"
                values="0 0; 100 0; 0 0"
              />
            </path>
          </svg>
          <svg className="absolute bottom-0 left-0 w-full" viewBox="0 0 1440 320" preserveAspectRatio="none">
            <path fill="rgba(255, 255, 255, 0.1)" fillOpacity="0.3" d="M0,64L48,80C96,96,192,128,288,128C384,128,480,96,576,122.7C672,149,768,235,864,256C960,277,1056,235,1152,197.3C1248,160,1344,128,1392,112L1440,96L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z">
              <animateTransform
                attributeName="transform"
                type="translate"
                dur="15s"
                repeatCount="indefinite"
                values="0 0; -100 0; 0 0"
              />
            </path>
          </svg>
        </div>

        <header className="flex justify-between items-center mb-8 relative z-10">
          <div className="flex-1">
            <img src={SailBoatIcon} alt="Cruise" className="mx-auto mb-3" width={40} height={40} />
            <img src={Logo} alt="Cruzero" className="mx-auto" width={140} height={34} />
          </div>
        </header>

        <main className="flex-grow flex flex-col justify-center items-center text-center relative z-10">
          <h1 className="text-5xl md:text-7xl font-bold mb-6 max-w-4xl">
            Where cruisers come to{" "}
            <AnimatePresence mode="wait">
              <motion.span
                key={currentPhrase}
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -20, opacity: 0 }}
                transition={{ duration: 0.5 }}
                className="bg-gradient-to-r from-yellow-200 to-orange-300 to-80% text-transparent bg-clip-text"
              >
                {phrases[currentPhrase]}
              </motion.span>
            </AnimatePresence>
          </h1>
          <p className="text-xl md:text-2xl mb-10 max-w-2xl">
            Introducing the only platform where cruisers can connect, share experiences and embark on unforgettable journeys together.
          </p>
          <div className="flex-row justify-between mb-4">
            <button
              onClick={() => setIsOpen(true)}
              className="bg-white text-primary font-bold py-3 px-8 mx-1.5 my-2 rounded-full text-lg hover:bg-opacity-90 transition duration-300 shadow-lg"
            >
              {joinedWaitlist && ( <Check className="w-6 h-6 mr-1 inline" />)}
              {joinedWaitlist ? `Joined` : 'Join the waitlist'}
            </button>
            <button
              onClick={() => setIsContactOpen(true)}
              className="bg-transparent border border-white mx-1.5 my-2 text-white py-3 px-8 rounded-full text-lg hover:border-blue-200 hover:text-blue-200 transition duration-300 shadow-lg"
            >
              Contact us
            </button>
          </div>
          <div className="flex flex-wrap justify-center items-center">
            <div className="flex -space-x-2 mr-3">
              {people.map((person, i) => (
                <img
                  key={i}
                  src={person}
                  alt={`User ${i + 1}`}
                  width={32}
                  height={32}
                  className="rounded-full shadow-md"
                />
              ))}
            </div>
            <span className="text-sm font-semibold">+350 others joined</span>
          </div>
        </main>

        <footer className="mt-16 grid grid-cols-1 md:grid-cols-3 gap-8 relative z-10">
          <div className="flex items-center bg-white/10 p-4 rounded-lg backdrop-blur-sm">
            <Anchor className="w-8 h-8 mr-4 flex-shrink-0" />
            <div className="self-start">
              <h3 className="text-lg font-bold underline">Connect</h3>
              <p className="text-base mt-2">
                Connect with fellow cruisers ahead of time and start the fun from the moment you step onboard!
              </p>
              <p className="text-base mt-2">
                Travelling solo? We know how expensive it can be to book a cabin alone. Find the right cabin mate and split the cost of your next adventure!
              </p>
            </div>
          </div>
          <div className="flex items-center bg-white/10 p-4 rounded-lg backdrop-blur-sm">
            <Ship className="w-8 h-8 mr-4 flex-shrink-0" />
            <div className="self-start">
              <h3 className="text-lg font-bold underline">Discover</h3>
              <p className="text-base mt-2">
                Find your dream cruise using our advanced filters and get personalized recommendations. Explore over 500+ ships across 30 cruise lines. See what other cruisers have said and share your own experiences!
              </p>
              <p></p>
            </div>
          </div>
          <div className="flex items-center bg-white/10 p-4 rounded-lg backdrop-blur-sm">
            <Compass className="w-8 h-8 mr-4 flex-shrink-0" />
            <div className="self-start">
              <h3 className="text-lg font-bold underline">Ask & Plan</h3>
              <p className="text-base mt-2">
                Have questions about your upcoming sailing or need help planning your next adventure? Ask the world's first Personal AI Cruise Assistant! Ask our AI to compare drink packages, recommend you a cruise, list your dining options, and more!
              </p>
            </div>
          </div>
        </footer>
        {/* Social Media Icons */}
        <div className="self-center mt-6">
          <a href="https://x.com/CruzeroApp" target='_blank' rel="noreferrer" className="inline-block mx-4 text-white hover:text-blue-300 transition-colors" aria-label="Follow us on Twitter">
            <Twitter className="w-6 h-6" />
          </a>
          <a href="https://www.instagram.com/cruzero.app" target='_blank' rel="noreferrer" className="inline-block mx-4 text-white hover:text-blue-300 transition-colors" aria-label="Follow us on Instagram">
            <Instagram className="w-6 h-6" />
          </a>
          <a href="https://www.facebook.com/profile.php?id=61565351473626" target='_blank' rel="noreferrer" className="inline-block mx-4 text-white hover:text-blue-300 transition-colors" aria-label="Follow us on Facebook">
            <Facebook className="w-6 h-6" />
          </a>
        </div>

        <Dialog open={isOpen} onClose={() => {
          setIsOpen(false)
          setIsSubmitted(false)
          setSelectedOptions([])
        }} className="relative z-50">
          <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
          <div className="fixed inset-0 flex items-center justify-center p-4">
            <DialogPanel className="bg-white rounded-lg p-6 max-w-sm w-full">
              {!isSubmitted ? (
                <>
                  <DialogTitle className="text-2xl font-bold text-gray-900 mb-4">Join the Waitlist</DialogTitle>
                  <form onSubmit={handleSubmit} className="space-y-4">
                    <input
                      type="email"
                      placeholder="Enter your email"
                      required
                      className="w-full px-3 py-2 text-gray-700 border rounded-md focus:outline-none focus:border-blue-500"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <button
                      type="submit"
                      className="w-full py-2 px-4 rounded-full font-bold text-white bg-primary hover:opacity-90 transition duration-300 shadow-md"
                      onSubmit={handleSubmit}
                    >
                      {isLoading ? Spinner : 'Join Now'}
                    </button>
                  </form>
                </>
              ) : (
                <>
                  <DialogTitle className="text-2xl font-bold text-gray-900 mb-4">What are you most excited about?</DialogTitle>
                  <div className="space-y-2">
                    {excitementOptions.map((option) => (
                      <button
                        key={option}
                        onClick={() => handleOptionToggle(option)}
                        className={`w-full text-left p-2 rounded-md flex items-center ${
                          selectedOptions.includes(option) ? 'bg-blue-600 text-white' : 'bg-gray-100 text-gray-700'
                        }`}
                      >
                        {selectedOptions.includes(option) ? <Check className="w-5 h-5 mr-2" /> : <Square className="w-5 h-5 mr-2" />}
                        {option}
                      </button>
                    ))}
                  </div>
                  <button
                    onClick={handleFinalSubmit}
                    className="w-full mt-4 py-2 px-4 rounded-full font-bold text-white bg-primary hover:opacity-90 transition duration-300 shadow-md"
                  >
                    {isLoading ? Spinner : 'Submit'}
                  </button>
                </>
              )}
            </DialogPanel>
          </div>
        </Dialog>

        <Dialog open={isContactOpen} onClose={() => setIsContactOpen(false)} className="relative z-50">
          <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
          <div className="fixed inset-0 flex items-center justify-center p-4">
            <DialogPanel className="bg-white rounded-lg p-6 max-w-sm w-full">
              <DialogTitle className="text-2xl font-bold text-gray-900 mb-4">Contact Us</DialogTitle>
              <form className="space-y-4">
                <div>
                  <label htmlFor="contact-name" className="block text-sm font-medium text-gray-700">Name</label>
                  <input type="text" id="contact-name" name="contact-name" required className="w-full px-3 py-2 text-gray-700 border rounded-md focus:outline-none focus:border-blue-500 mt-1"
                    value={contactName}
                    onChange={(e) => setContactName(e.target.value)}
                  />
                </div>
                <div>
                  <label htmlFor="contact-email" className="block text-sm font-medium text-gray-700">Email</label>
                  <input type="email" id="contact-email" name="contact-email" required className="w-full px-3 py-2 text-gray-700 border rounded-md focus:outline-none focus:border-blue-500 mt-1"
                    value={contactEmail}
                    onChange={(e) => setContactEmail(e.target.value)}
                  />
                </div>
                <div>
                  <label htmlFor="message" className="block text-sm font-medium text-gray-700">Message</label>
                  <textarea id="message" name="message" rows={4} required className="w-full px-3 py-2 text-gray-700 border rounded-md focus:outline-none focus:border-blue-500 mt-1"
                    value={contactMessage}
                    onChange={(e) => setContactMessage(e.target.value)}
                  ></textarea>
                </div>
                <button type="submit" className="w-full bg-primary text-white font-bold py-2 px-4 rounded-full hover:opacity-90 transition duration-300 shadow-md"
                  onClick={handleContactSubmit}
                >
                  {isLoading ? Spinner : 'Send Message'}
                </button>
              </form>
            </DialogPanel>
          </div>
        </Dialog>
      </div>
    </>
  )
}