// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore/lite';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCLkzI-6j3K62ernXhZjpAKmqY6SqVlsQ0",
  authDomain: "cruzero-waitlist.firebaseapp.com",
  projectId: "cruzero-waitlist",
  storageBucket: "cruzero-waitlist.appspot.com",
  messagingSenderId: "458574363846",
  appId: "1:458574363846:web:48c3446e2a9fc27690abc7",
  measurementId: "G-Y374741NN8"
};

// Initialize Firebase
const firebase = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebase);
const db = getFirestore(firebase);

export { firebase, analytics, db };